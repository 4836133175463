import React from "react";
// import IInfoContact from "../../images/i-info-contact.svg"
import IInfoMail from "../../images/i-info-mail.svg";
// import IInfoNumber from "../../images/i-info-number.svg"
import IInfoWeb from "../../images/i-info-web.svg";
// import IInfoIn from "../../images/i-info-in.svg"
import IInfoIg from "../../images/i-info-ig.svg";
import IInfoTw from "../../images/i-info-tw.svg";
import IInfoFb from "../../images/i-info-fb.svg";
// import IGreenWs2 from "../../images/i-green-ws-2.svg"
// import _1BlueMsn from "../../images/1-blue-msn.svg"
import moment from "moment";
import "moment/locale/es";
import { Link } from "gatsby";
import CabeceraInterna from "./cabeceraInterna";
import ordenarEventos from "../../helpers/ordenarEventos";
import { navigate } from "@reach/router";

const InternaScreen = ({ resultData }) => {
  moment.locale("es");

  const { evento, eventos, variablesEntorno } = resultData;
  let {
    id,
    Descripcion,
    organizadoPor,
    Descarga,
    agenda_master_etiquetas,
    agenda_master_unidads,
    MasInformacion,
    linkTransmisionEnVivo =""
  } = evento;

  let m1 = [];
  let m2 = [];
  if (MasInformacion.length > 0) {
    m1 = MasInformacion.slice(0, Math.ceil(MasInformacion.length / 2));
    m2 = MasInformacion.slice(
      Math.ceil(MasInformacion.length / 2),
      MasInformacion.length
    );
  }

  const relacionados = [];

  let eventosOrdenados = [];
  if (evento.tipo_evento) {
    if (evento.tipo_evento.TipoEvento === "CON") {
      const aux = ordenarEventos(eventos)
        .map((e) => e.evento)
        .filter((e) =>
          e.tipo_evento ? e.tipo_evento.TipoEvento === "CON" : false
        );

      eventosOrdenados = aux;
    } else {
      const aux = ordenarEventos(eventos)
        .map((e) => e.evento)
        .filter((e) =>
          e.tipo_evento ? e.tipo_evento.TipoEvento !== "CON" : false
        );
      eventosOrdenados = aux;
    }
  }
  const eventosFormat = eventosOrdenados.map((e) => ({
    evento: e,
    etiquetasId: e.agenda_master_etiquetas.map((et) => et.id),
  }));

  const etiquetasId = agenda_master_etiquetas.map((et) => et.id);

  eventosFormat.forEach((ef) => {
    etiquetasId.forEach((idet) => {
      if (ef.etiquetasId.includes(idet)) {
        const existe = relacionados.find((r) => r.id === ef.evento.id);
        if (!existe && ef.evento.id !== id) {
          relacionados.push(ef.evento);
        }
      }
    });
  });

  const handleNavigate = (slug) => {
    navigate(`/evento/${slug}`);
  };
  const strlinkEnVivo = `<br/><p><b><a className="download-link" href="${linkTransmisionEnVivo}" target="_blank">Transmisión en vivo</a></b></p>`;
  Descripcion =Descripcion + ((linkTransmisionEnVivo && linkTransmisionEnVivo!==null && linkTransmisionEnVivo.length>0)? strlinkEnVivo:"");

  //console.log("linkTransmisionEnVivo ????: " + linkTransmisionEnVivo);
  return (
    <>
      <CabeceraInterna evento={evento} variablesEntorno={variablesEntorno} />
      <div className="sec-interna-info">
        <div className="div-background-img"></div>
        <div className="container-1200 w-container">
          <div className="flex-interna">
            <div className="wrap-info">
              {Descripcion && (
                <div
                  className="rich-text-interna w-richtext"
                  dangerouslySetInnerHTML={{ __html: Descripcion }}
                ></div>
              )}


              {Descarga.length > 0 && (
                <div>
                  <h2 className="heading-2">Descargas</h2>
                  <ul className="ul-downlload">
                    {Descarga.map((d) => (
                      <li className="ul-li-download" key={d.hash}>
                        <a
                          href={`${variablesEntorno.AGENDA_APP_SITE + d.url}`}
                          target="_blank"
                          rel="noreferrer"
                          className="download-link"
                        >
                          {`${d.name}  | ${d.ext} - ${d.size}kb`}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {(agenda_master_unidads.length > 0 ||
                (organizadoPor && organizadoPor.otrasInstituciones)) && (
                <div>
                  <h2 className="heading-2">Organizado por</h2>
                  <div className="rich-text-interna w-richtext">
                    <ul>
                      {agenda_master_unidads.length > 0 &&
                        agenda_master_unidads.map((unidad) => (
                          <li key={id}>{unidad.nombre}</li>
                        ))}
                      {organizadoPor && organizadoPor.otrasInstituciones && (
                        <li>{organizadoPor.otrasInstituciones}</li>
                      )}
                    </ul>
                  </div>
                </div>
              )}
              {MasInformacion.length > 0 && (
                <div className="wrap-mas-info">
                  <h3 className="h3-mas">Más información</h3>
                  <div className="flex-interna _10">
                    <div className="div-50-more-info">
                      {m1.map((m) => {
                        return (
                          <div
                            key={m.id}
                            className="cont-flex-info-event more-info"
                          >
                            <div className="icon-wrapper-65">
                              <img
                                src={
                                  m.TipoLink
                                    ? m.TipoLink.toLowerCase() === "twitter"
                                      ? IInfoTw
                                      : m.TipoLink.toLowerCase() === "correo"
                                      ? IInfoMail
                                      : m.TipoLink.toLowerCase() === "facebook"
                                      ? IInfoFb
                                      : m.TipoLink.toLowerCase() === "instagram"
                                      ? IInfoIg
                                      : m.TipoLink.toLowerCase() === "link"
                                      ? IInfoWeb
                                      : IInfoWeb
                                    : IInfoWeb
                                }
                                loading="lazy"
                                alt="Web"
                              />
                            </div>
                            <a
                              href={m.Link}
                              target="_blank"
                              rel="noreferrer"
                              className="link-more-info"
                            >
                              {m.Descripcion ? m.Descripcion : m.Link}
                              <br />
                            </a>
                          </div>
                        );
                      })}

                      {/* <div className="cont-flex-info-event more-info">
                      <div className="icon-wrapper-65">
                        <img src={IInfoContact} loading="lazy" alt="Contacto" />
                      </div>
                      <div>Jose Carlos Benavides</div>
                    </div>

                    <div className="cont-flex-info-event more-info">
                      <div className="icon-wrapper-65">
                        <img
                          src={IInfoNumber}
                          loading="lazy"
                          alt="Número de contacto"
                        />
                      </div>
                      <div>626-2000 anexo 2177</div>
                    </div> */}
                    </div>
                    <div className="div-50-more-info">
                      {m2.map((m) => {
                        return (
                          <div
                            key={m.id}
                            className="cont-flex-info-event more-info"
                          >
                            <div className="icon-wrapper-65">
                              <img
                                src={
                                  m.TipoLink
                                    ? m.TipoLink.toLowerCase() === "twitter"
                                      ? IInfoTw
                                      : m.TipoLink.toLowerCase() === "correo"
                                      ? IInfoMail
                                      : m.TipoLink.toLowerCase() === "facebook"
                                      ? IInfoFb
                                      : m.TipoLink.toLowerCase() === "instagram"
                                      ? IInfoIg
                                      : m.TipoLink.toLowerCase() === "link"
                                      ? IInfoWeb
                                      : IInfoWeb
                                    : IInfoWeb
                                }
                                loading="lazy"
                                alt="Web"
                              />
                            </div>
                            <a
                              href={m.Link}
                              target="_blank"
                              rel="noreferrer"
                              className="link-more-info"
                            >
                              {m.Descripcion ? m.Descripcion : m.Link}

                              <br />
                            </a>
                          </div>
                        );
                      })}

                      {/* <div className="cont-flex-info-event more-info">
                      <div className="icon-wrapper-65">
                        <img src={IInfoIn} loading="lazy" alt="Linkedin" />
                      </div>
                      <a href="#" className="link-more-info">
                        Linkedin
                        <br />
                      </a>
                    </div>
                    <div className="cont-flex-info-event more-info">
                      <div className="icon-wrapper-65">
                        <img src={IInfoIg} loading="lazy" alt="Instagram" />
                      </div>
                      <a href="#" className="link-more-info">
                        Instagram
                        <br />
                      </a>
                    </div> */}
                    </div>
                  </div>
                  {/* <div className="block-info-convocatoria">
                    <div>
                      Si tiene alguna duda o consulta sobre la convocatoria
                      puede conversar con nosotros
                    </div>
                    <div className="flex-interna _10">
                      <div className="div-50-more-info">
                        <button
                          type="button"
                          className="enlace-convo w-inline-block"
                        >
                          <div className="icon-wrapper-big">
                            <img
                              src={IGreenWs2}
                              loading="lazy"
                              alt="Whatsapp"
                            />
                          </div>
                          <div>Whatsapp</div>
                        </button>
                      </div>
                      <div className="div-50-more-info">
                        <button
                          type="button"
                          className="enlace-convo w-inline-block"
                        >
                          <div className="icon-wrapper-big">
                            <img
                              src={_1BlueMsn}
                              loading="lazy"
                              alt="Facebook Messenger"
                            />
                          </div>
                          <div>Facebook Msn</div>
                        </button>
                      </div>
                    </div>
                  </div> */}
                </div>
              )}
              {agenda_master_etiquetas.length > 0 && (
                <div className="wrap-tags">
                  <div className="cont-tags">
                    {agenda_master_etiquetas.map((e) => (
                      <Link
                        type="button"
                        key={e.id}
                        className="link-tag interna new-button"
                        to="/etiquetas"
                        state={{ etiqueta: e.Nombre }}
                      >
                        {e.Nombre}
                      </Link>
                    ))}
                  </div>
                </div>
              )}

              <div>
                <Link to="/home" className="link-back">
                  Volver a la lista de eventos
                </Link>
              </div>
            </div>
            {relacionados.length > 0 && (
              <div className="wrap-relac">
                <div className="cont-rel">
                  <h3 className="h3-rel">eventos relacionados</h3>
                  <div className="wrap-event-rel">
                    {relacionados.map(
                      (r, i) =>
                        i < 3 && (
                          <div key={r.id} className="event-container-rel">
                            <button
                              type="button"
                              className="event-card rel w-inline-block new-button"
                              onClick={() => handleNavigate(r.slug)}
                            >
                              <div className="img-event-container rel">
                                {r.ImagenDestacada && (
                                  <img
                                    alt={r.slug}
                                    src={`${
                                      variablesEntorno.AGENDA_APP_SITE +
                                      r.ImagenDestacada.url
                                    }`}
                                    className="img-event"
                                  />
                                )}

                                {r.tipo_evento && (
                                  <div className="type-label">
                                    <div className="tipo-label rel">
                                      {r.tipo_evento.Nombre}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="info-event-container">
                                <h3 className="h3-card-event rel">
                                  {r.Titulo}
                                </h3>
                              </div>
                            </button>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InternaScreen;
